/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        //-----------------------------
        // Stock notice when max QTY Reached
        //-----------------------------

        function maxreached(){
          var $_GET = {};
          document.location.search.replace(/\??(?:([^=]+)=([^&]*)&?)/g, function () {
            function decode(s) {
              return decodeURIComponent(s.split("+").join(" "));
            }
            $_GET[decode(arguments[1])] = decode(arguments[2]);
          });

          $('.input-text.qty.text').each(function(){

            minValue =  parseInt($(this).attr('min'));
            maxValue =  parseInt($(this).attr('max'));
            valueCurrent = parseInt($(this).val());
            
            name = $(this).attr('name');
            if(valueCurrent == maxValue) {
              if (($_GET['lang'])=="en") {
                if (($(this).parent().children('.stocknotice').length)===0){
                  $(this).parent().append('<div class="stocknotice">Max qty reached</div>');
                }
              }
              else{
                if (($(this).parent().children('.stocknotice').length)===0){
                  $(this).parent().append('<div class="stocknotice">Qté maximale atteinte</div>');
                }
              }
            }
            else{
              $(this).parent().find('.stocknotice').remove();
            }
          });
        }

        //-----------------------------
        // SIMPLE TOOLTIP 
        //-----------------------------


        function simple_tooltip(target_items, name){
         $(target_items).each(function(i){
            $("body").append("<div class='"+name+"' id='"+name+i+"'><p>"+$(this).attr('title')+"</p></div>");
            var my_tooltip = $("#"+name+i);

            $(this).removeAttr("title").mouseover(function(){
                my_tooltip.css({opacity:0.8, display:"none"}).fadeIn(400);
            }).mousemove(function(kmouse){
                my_tooltip.css({left:kmouse.pageX+15, top:kmouse.pageY+15});
            }).mouseout(function(){
                my_tooltip.fadeOut(400);
            });
          });
        }


        function showhidefilter(){

          $("#showhidefilter").click(function(e){
              e.preventDefault();
              $(".woofilter").slideToggle();
          });
        }


        function fullscreen2(){
          jQuery('body.home .slideshow-container').css({
              width: jQuery(window).width(),
              height: jQuery(window).height()-(8+jQuery('.topHeader').height()+jQuery('.navbar').height())
          });
        }

        //-----------------------------
        // WC - Show and hide field depending of country and shiiping method
        //-----------------------------

        function hide_or_show_checkout_field(){
          if ( 
            $('#s2id_billing_country .select2-chosen').html() == "Italie" ||
            $('#s2id_billing_country .select2-chosen').html() == "Italy" ||
            $('#s2id_billing_country .select2-chosen').html() == "Taiwan" ||
            $('#s2id_billing_country .select2-chosen').html() == "Liechtenstein" ||
            $('#s2id_billing_country .select2-chosen').html() == "République Tchèque" ||
            $('#s2id_billing_country .select2-chosen').html() == "Czech Republic" ||
            $('#s2id_billing_country .select2-chosen').html() == "Ukraine" ||
            $('#s2id_billing_country .select2-chosen').html() == "Japon" ||
            $('#s2id_billing_country .select2-chosen').html() == "Japan" 
            ) {
            //alert("Youyouyou");
            $('.infos_supplementaire').show();
          }
          else {
            $('.infos_supplementaire').hide();
          }
          //alert ($('#select2-chosen-1').html());
          if (
            $('#shipping_method_0_table_rate111').val() || 
            $('#shipping_method_0_local_pickup6').val() || 
            ($('#shipping_method_0').val()!=="table_rate:2:31" && $('#shipping_method_0').val())||
            ($('#shipping_method_0').val()!=="table_rate:2:30" && $('#shipping_method_0').val())

          
          ){
              $('.woocommerce-billing-fields h3').show();
              $('h3.woocommerce-preorder-fields').hide();
              $('.preorder_notice').remove();
              $('.woocommerce-checkout .page-header h1').show();

              $('#billing_first_name_field').show();
              if ($('#billing_first_name').val()=="Anonymous"){
                $('#billing_first_name').val("");
              }

              $('#billing_last_name_field').show();
              if ($('#billing_last_name').val()=="Anonymous"){
                $('#billing_last_name').val("");
              }


              $('#billing_company_field').show();


              $('#billing_phone_field').show();
              if ($('#billing_phone').val()=="0000000000"){
                $('#billing_phone').val("");
              }

              $('#billing_address_1').show();
              $('#billing_address_1_field').show();
              if ($('#billing_address_1').val()=="Précommande"){
                $('#billing_address_1').val("");
              }

              $('#billing_address_2').show();
              //$('#billing_address_2_field').show();

              $('#billing_city_field').show();
              if ($('#billing_city').val()=="Précommande"){
                $('#billing_city').val("");
              }

              $('#billing_state_field').show();
              if ($('#billing_state').val()=="Précommande"){
                $('#billing_state').val("");
              }

              $('.wc-terms-and-conditions').show();
              $('.wc-terms-and-conditions #terms').prop('checked', false);

              $('.col-22').show();

              if(!($("#ship-to-different-address-checkbox").is(':checked'))){
                $(".shipping_address").hide();
                if (typeof select2 == 'function') { 
                  $("#s2id_shipping_country").select2("val", ""); 
                }
                
              }

              //alert ("youhou");
          }
          else if (!($('#shipping_method_0').val())){
              $('.woocommerce-checkout .page-header h1').hide();

              if (($('.preorder_notice').length)===0){
                //$('.woocommerce-checkout .page-header').append($('tr.shipping td').wrap("<div class='new'></div>").html());
                $('.woocommerce-checkout .page-header').append("<div class='preorder_notice'></div>");
                $('.preorder_notice').append($('tr.shipping td').html());
              }

              $('.woocommerce-billing-fields h3').hide();
              $(' h3.woocommerce-preorder-fields').show();

              $('#billing_first_name_field').hide();
              if (!($('#billing_first_name').val())){
                $('#billing_first_name').val("Anonymous");
              }
              
              
              $('#billing_last_name_field').hide();
              if (!($('#billing_last_name').val())){
                $('#billing_last_name').val("Anonymous");
              }
              $('#billing_company_field').hide();

              $('#billing_phone_field').hide();
              if (!($('#billing_phone').val())){
                $('#billing_phone').val("0000000000");
              }
              $('#billing_address_1_field').hide();
              $('#billing_address_1').hide();
              if (!($('#billing_address_1').val())){
                $('#billing_address_1').val("Précommande");
              }

              $('#billing_address_2').hide();
              $('#billing_address_2_field').hide();

              $('#billing_city_field').hide();
              if (!($('#billing_city').val())){
                $('#billing_city').val("Précommande");
              }

              $('#billing_state_field').hide();
              if (!($('#billing_state').val())){
                $('#billing_state').val("Précommande");
              }
              if($("#ship-to-different-address-checkbox").is(':checked')){
                $("#ship-to-different-address-checkbox").attr('checked', false);
                if (typeof select2 == 'function') { 
                  $("#s2id_billing_country").select2("val", "");
                }
              }
              
              /*
              if ($("#select2-chosen-2").html()){
                $("#s2id_billing_country").select2("val", "");
                $("#s2id_shipping_country").select2("val", "");
              }
              */
              


              $('.wc-terms-and-conditions').hide();
              $('.wc-terms-and-conditions #terms').prop('checked', true);

              $('.col-22').hide();
              //alert ("ahah");
          }
        }


        /*
        jQuery(window).resize(function() {
           fullscreen2();         
        });
        */




        //-----------------------------
        /*----------  On document ready  ----------*/
        //-----------------------------
        
        jQuery(function() {

          //fullscreen2();
          simple_tooltip(".custombadge","tooltip");
          showhidefilter();
          var url = window.location.href;
          var host = window.location.host;
          if (url.indexOf('http://' + host + '/commande') != -1) {
             //hide_or_show_checkout_field();
             //alert ('this is it');
          }
          
          

          $('.sidebar select').niceSelect();

          var slidecount = $(".header-slideshow > div").length;
          //alert (slidecount);
          var number = Math.ceil(Math.random() * slidecount-1);
          //alert (number);
          /*$('body.home .header-slideshow').slick({
            autoplay:true,
            autoplaySpeed: 2500,
            dots:false,
            easing:'swing',
            fade:true,
            speed: 2500,
            pauseOnFocus: false,
            pauseOnHover: false,
            initialSlide: number
          });
          */

          // Add smooth scrolling to all links
          $("a.smoothscroll").on('click', function (event) {

            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
              // Prevent default anchor click behavior
              event.preventDefault();

              // Store hash
              var hash = this.hash;

              // Using jQuery's animate() method to add smooth page scroll
              // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
              $('html, body').animate({
                scrollTop: $(hash).offset().top
              }, 800, function () {

                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
              });
            } // End if
          });

          $('.featuredProduct').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            lazyLoad: 'ondemand',
            swipeToSlide:true,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 1059,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 832,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]            
          });

          /*----------  On veut récupérer la variable GET  ----------*/

          maxreached();
          var $_GET = {};
          document.location.search.replace(/\??(?:([^=]+)=([^&]*)&?)/g, function () {
            function decode(s) {
              return decodeURIComponent(s.split("+").join(" "));
            }
            $_GET[decode(arguments[1])] = decode(arguments[2]);
          });

          //alert($_GET['filtering']);          
          if (($_GET['filtering'])==1) {
            $('html, body').animate({ scrollTop: $('#topshop').offset().top}, 100);
          }
          if(window.location.href.indexOf("/page/") > -1) {
            $('html, body').animate({ scrollTop: $('#topshop').offset().top}, 100);
          }

          $("div.loginform").hide();
          $('#loginaccount').change(function () {
            $("div.loginform").hide();
            $(this).is(":checked") && $("div.loginform").slideDown();
          });
          //


          $('.input-text.qty.text').change(function() {
    
            minValue =  parseInt($(this).attr('min'));
            maxValue =  parseInt($(this).attr('max'));
            valueCurrent = parseInt($(this).val());
            
            name = $(this).attr('name');
            if(valueCurrent == maxValue) {
              if (($_GET['lang'])=="en") {
                if (($(this).parent().children('.stocknotice').length)===0){
                  $(this).parent().append('<div class="stocknotice">Max qty reached</div>');
                }
              }
              else{
                if (($(this).parent().children('.stocknotice').length)===0){
                  $(this).parent().append('<div class="stocknotice">Qté maximale atteinte</div>');
                }
              }
            }
            else{
              $(this).parent().find('.stocknotice').remove();
            }
          });
         
        });
       

        /* Callback lorsque le checkout se met à jour */

        $( document.body ).on( 'updated_checkout', function() {
          $('.preorder_notice').remove();
          //hide_or_show_checkout_field();

        });
        
        /* Callback lorsque qu'on change le lieu de livraison */

        $( document.body ).on( 'updated_shipping_method', function() {
          maxreached();
          
          $('.input-text.qty.text').change(function() {
            var $_GET = {};
            document.location.search.replace(/\??(?:([^=]+)=([^&]*)&?)/g, function () {
              function decode(s) {
                return decodeURIComponent(s.split("+").join(" "));
              }
              $_GET[decode(arguments[1])] = decode(arguments[2]);
            });
            minValue =  parseInt($(this).attr('min'));
            maxValue =  parseInt($(this).attr('max'));
            valueCurrent = parseInt($(this).val());
            
            name = $(this).attr('name');
            if(valueCurrent == maxValue) {
              if (($_GET['lang'])=="en") {
                $(this).parent().append('<div class="stocknotice">Max qty reached</div>');
              }
              else{
                $(this).parent().append('<div class="stocknotice">Qté maximale atteinte</div>');
              }
            }
            else{
              $(this).parent().find('.stocknotice').remove();
            }
          });
        });
        
        $( document.body ).on( 'updated_cart_totals', function() {        

          $('.input-text.qty.text').change(function() {
            maxreached();

            var $_GET = {};
            document.location.search.replace(/\??(?:([^=]+)=([^&]*)&?)/g, function () {
              function decode(s) {
                return decodeURIComponent(s.split("+").join(" "));
              }
              $_GET[decode(arguments[1])] = decode(arguments[2]);
            });
            minValue =  parseInt($(this).attr('min'));
            maxValue =  parseInt($(this).attr('max'));
            valueCurrent = parseInt($(this).val());
            
            name = $(this).attr('name');
            if(valueCurrent == maxValue) {
              if (($_GET['lang'])=="en") {
                $(this).parent().append('<div class="stocknotice">Max qty reached</div>');
              }
              else{
                $(this).parent().append('<div class="stocknotice">Qté maximale atteinte</div>');
              }
            }
            else{
              $(this).parent().find('.stocknotice').remove();
            }
          });
        });

        

        /*---------- Pour le zozo tab des related products ----------*/

        $("#tabbed-nav").zozoTabs({
          animation: {
            duration: 800,
            effects: "slideH"
          }
        }); 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
